import React from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';

import yellowcrv from '../../assets/images/pattern-graphic-yellow.png';
// import logo from '../../assets/images/logo.png';
import round1 from '../../assets/images/RoundOne1-symbol.svg';
import { Link } from 'react-router-dom';
import {
  InstagramEmbed,
  FacebookEmbed,
  YouTubeEmbed,
  LinkedInEmbed,
} from 'react-social-media-embed';

const SocialFeed = () => {
  return (
    <div className="sfbg relbox">
      <Image src={yellowcrv} alt="" className="ylw-graphic-two" />
      <Container>
        <h2 className="hd-titles text-center py-5">Social Feed</h2>
        <Row className="pb-5">
          <Col lg={4} md={4} sm={12}>
            <div className="fulheight relbox">
              {/* <Link
                to="https://www.facebook.com/roundoneph"
                target="_blank"
                style={{ textDecoration: 'none' }}
              > */}
              <h4 className="text-left p-2 m-0 th-scl">Facebook</h4>
              {/* </Link> */}
              <div className="curvebx shadw mb-3">
                <div className="topscl-info scrollbar scrollbar-primary">
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <iframe
                      src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Froundoneph%2Fposts%2Fpfbid02nDZXmQBSjvjYp4jkyYmz61boyc5DzArotKatpr53RA6GUx9Ybre3tqrg8HdfjBi5l&show_text=true&width=500"
                      width="328"
                      height="500"
                      scrolling="no"
                      frameborder="0"
                      allowfullscreen="true"
                      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    ></iframe>
                  </div>
                  <div className="d-flex justify-content-end px-3 pb-3">
                    <Link
                      to="https://www.facebook.com/photo/?fbid=859869022808979&set=a.546910727438145"
                      target="_blank"
                      style={{ textDecoration: 'none' }}
                    >
                      <Button variant="link">View Post</Button>
                    </Link>
                  </div>
                </div>
                <Link
                  to="https://www.facebook.com/roundoneph"
                  target="_blank"
                  style={{ textDecoration: 'none' }}
                >
                  <div className="btm-socialbx d-flex justify-content-between align-items-center p-2">
                    <div className="d-flex flex-row">
                      <Image src={round1} alt="" className="social-rlogo" />
                      <small className="p-2">
                        <b>Round One</b>
                      </small>
                    </div>
                    <i className="fa-brands fa-facebook-f p-2 fsize-icon color-fb"></i>
                  </div>
                </Link>
              </div>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12}>
            <div className="fulheight relbox">
              {/* <Link
                to="https://www.instagram.com/roundoneph/"
                target="_blank"
                style={{ textDecoration: 'none' }}
              > */}
              <h4 className="text-left p-2 m-0 th-scl">Instagram</h4>
              {/* </Link> */}
              <div className="curvebx shadw mb-3">
                <div className="topscl-info scrollbar scrollbar-primary">
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <InstagramEmbed
                      url="https://www.instagram.com/p/Cz70epuBjGO/"
                      width={328}
                    />
                  </div>
                  <div className="d-flex justify-content-end px-3 pb-3">
                    <Link
                      to="https://www.instagram.com/p/Cz70epuBjGO/"
                      target="_blank"
                      style={{ textDecoration: 'none' }}
                    >
                      <Button variant="link">View Post</Button>
                    </Link>
                  </div>
                </div>
                <Link
                  to="https://www.instagram.com/roundoneph/"
                  target="_blank"
                  style={{ textDecoration: 'none' }}
                >
                  <div className="btm-socialbx d-flex justify-content-between align-items-center p-2">
                    <div className="d-flex flex-row">
                      <Image src={round1} alt="" className="social-rlogo" />
                      <small className="p-2">
                        <b>Round One</b>
                      </small>
                    </div>
                    <i className="fa-brands fa-instagram p-2 fsize-icon color-fb"></i>
                  </div>
                </Link>
              </div>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12}>
            <div className="fulheight relbox">
              {/* <Link
                to="https://www.youtube.com/channel/UCM1jgfa26zIxclzQT6lpbcQ"
                target="_blank"
                style={{ textDecoration: 'none' }}
              > */}
              <h4 className="text-left p-2 m-0 th-scl">LinkedIn</h4>
              {/* </Link> */}
              <div className="curvebx shadw mb-3">
                <div className="topscl-info scrollbar scrollbar-primary">
                  {/* <Image
                    src="https://images.inc.com/uploaded_files/image/1920x1080/getty_499517325_111832.jpg"
                    alt=""
                  />
                  */}
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <LinkedInEmbed
                      url="https://www.linkedin.com/embed/feed/update/urn:li:share:7160926898716299264"
                      width={328}
                    />
                  </div>
                  {/* <p className="p-2 m-0">
                    Round One is a business community that connects businesses
                    with incubators, advisors, funders, and other organizations
                    that can guide, support, and educate entrepreneurs in their
                    ventures. We aspire to encourage MSMEs in the Philippines to
                    support each other by providing the tools, community, and
                    digital ecosystem to help build better businesses.
                  </p> */}
                  <div className="d-flex justify-content-end px-3 pb-3">
                    <Link
                      to="https://www.linkedin.com/company/roundoneph"
                      target="_blank"
                      style={{ textDecoration: 'none' }}
                    >
                      <Button variant="link">View Post</Button>
                    </Link>
                  </div>
                </div>
                <Link
                  to="https://www.youtube.com/channel/UCM1jgfa26zIxclzQT6lpbcQ"
                  target="_blank"
                  style={{ textDecoration: 'none' }}
                >
                  <div className="btm-socialbx d-flex justify-content-between align-items-center p-2">
                    <div className="d-flex flex-row">
                      <Image src={round1} alt="" className="social-rlogo" />
                      <small className="p-2">
                        <b>Round One</b>
                      </small>
                    </div>
                    <i className="fa-brands fa-linkedin-in p-2 fsize-icon color-fb"></i>
                  </div>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SocialFeed;
